/* Lazy loading images */
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';

window.lazySizesConfig = window.lazySizesConfig || {};
// window.lazySizesConfig.loadHidden = false;
window.lazySizesConfig.customMedia = {
  '--small': '(max-width: 767px)',
  '--medium': '(max-width: 991px)',
  '--large': '(max-width: 1199px)',
  '--xlarge': '(max-width: 1499px)',
  '--rlxMobile': '(max-width: 767px)',
  '--rlxTablet': '(max-width: 1023px)',
};
