// TODO: convert this to vanilla js/typescript at some point
import Client from 'shopify-buy/index.unoptimized.umd';

import $ from 'jquery';
import littledata from '@littledata/headless-shopify-sdk';

import { formatAsMoney, extractID, getProductWithPublicSku } from './utils';

import updateVariantImage from './product/update-variant-image';

const domain = process.env.SHOPIFY_DOMAIN;
const storefrontAccessToken = process.env.SHOPIFY_STOREFRONT_TOKEN;
let client;
let cart;

$(() => {
  Promise.resolve()
    .then(() => {
      client = Client.buildClient({
        domain,
        storefrontAccessToken,
      });

      // check if a cart already exists in local storage
      if (localStorage.getItem('lastCartId') != null) {
        // get the cart from local storage if it exists
        return Promise.resolve(
          client.checkout.fetch(localStorage.getItem('lastCartId'))
        );
      }
      return Promise.resolve(false);
    })
    .then((storedCart) => {
      if (storedCart && storedCart.order == null) {
        // render the stored Cart
        cart = storedCart;

        const productUpdates = [];

        cart.lineItems.forEach((product) => {
          // check that the product still exists in Shopify
          if (product.variant === null) {
            productUpdates.push(product.id);
          }
        });

        if (productUpdates.length > 0) {
          // if there are products that no longer exist still in the cart, remove them
          return Promise.resolve(
            client.checkout.removeLineItems(cart.id, productUpdates)
          );
        }
        return Promise.resolve(false);
      }
      // else create a new cart
      return Promise.resolve(client.checkout.create());
    })
    .then((checkout) => {
      if (checkout) {
        // render the new checkout
        cart = checkout;
        localStorage.setItem('lastCartId', cart.id);

        updateCartTabButton(cart);
      } else {
        updateCartTabButton(cart);
      }

      // Add littledata client IDs to shopify checkout
      if (Cookiebot?.consent.statistics) {
        littledata
          .fetchClientIds({
            ga4MeasurementId: 'G-0QLGEFWDB3',
            fbPixelId: '306601538442852',
          })
          .then((ids) =>
            client.checkout.updateAttributes(cart.id, { customAttributes: ids })
          );

        // Send checkout info to littledata
        littledata.sendCheckoutToLittledata(cart.id);
      }

      // bind everything
      bindEventListeners();

      // set valid selectors on single product pages
      initVariantSelectors();

      return Promise.resolve(true);
    })
    .catch((err) => {
      console.error(err);
    });
});

function initVariantSelectors() {
  if (!document.querySelector('main.product')) {
    return;
  }

  if (!document.querySelector('.js-variant-selector')) {
    return;
  }

  // let firstSelected = document.querySelector(
  //   '.js-variant-selector input:checked'
  // );

  // let name = firstSelected.dataset.option;
  // let value = firstSelected.value;
  // let handle = firstSelected.dataset.product;

  // getProductWithPublicSku({ client, handle }).then((product) => {
  //   showAvailableVariants(name, value, product);
  // });

  let $selectedVariants = document.querySelectorAll(
    '.js-variant-selector input:checked'
  );

  $selectedVariants.forEach(($selectedVariant) => {
    const name = $selectedVariant.dataset.option;
    const value = $selectedVariant.value;
    const handle = $selectedVariant.dataset.product;

    getProductWithPublicSku({ client, handle }).then((product) => {
      showAvailableVariants(name, value, product);
    });
  });
}

/* Bind Event Listeners
      ============================================================ */
function bindEventListeners() {
  /* buy button click listener for single product pages */

  let addToCartBtns = document.querySelectorAll('.product .js-add-to-cart');

  addToCartBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      btn.setAttribute('disabled', 'true');
      btn.classList.add('processing');

      const variantID = btn.dataset.id;

      let customAttributes = [];

      let ringSizeSelectors = document.querySelector(
        '.single-product__ring-size-selectors'
      );

      if (ringSizeSelectors) {
        let ringSize =
          ringSizeSelectors.querySelector('input:checked').dataset.ringsize;

        customAttributes.push({
          key: 'Size',
          value: ringSize,
        });
      }

      buyButtonClickHandler(variantID, e, customAttributes);
    });
  });

  let quickBuyBtns = document.querySelectorAll('.js-buy-now');

  quickBuyBtns.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      btn.setAttribute('disabled', 'true');
      btn.classList.add('processing');

      const variantID = btn.dataset.id;

      let customAttributes = [];

      let ringSizeSelectors = document.querySelector(
        '.single-product__ring-size-selectors'
      );

      if (ringSizeSelectors) {
        let ringSize =
          ringSizeSelectors.querySelector('input:checked').dataset.ringsize;

        customAttributes.push({
          key: 'ringSize',
          value: ringSize,
        });
      }

      buyButtonClickHandler(variantID, e, customAttributes, true);
    });
  });

  // collection add to carts
  // TODO: figure out how to export the function so that its not run multiple times on instantsearch.js file

  $('.collection, .search-results__product-grid, .product-list, .featured-product').on(
    'click',
    '.js-add-to-cart',
    (e) => {
      const btn = e.currentTarget;

      const variantID = btn?.dataset.id;

      // btn.setAttribute('disabled', 'true');
      btn.classList.add('processing');

      buyButtonClickHandler(variantID, e, []);
    }
  );

  let variantSelectors = document.querySelectorAll('.js-variant-selector');

  variantSelectors.forEach((selector) => {
    let input = selector.querySelector('input');

    input.addEventListener('change', () => {
      let name = input.dataset.option;
      let value = input.value;
      let quantity = 1;
      let handle = input.dataset.product;

      let allInputs = document.querySelectorAll(
        `.js-variant-selector input[data-product="${handle}"]:checked`
      );

      let selectedOptions = [...allInputs].map((input) => {
        return {
          name: input.dataset.option,
          value: input.value,
        };
      });

      // update label if it exists
      let label = document.querySelector(
        `.js-selected-option[data-option="${name}"]`
      );

      if (label) {
        label.innerHTML = `${name}: ${value}`;
      }

      getProductWithPublicSku({ client, handle }).then((product) => {
        setSelectedVariant(name, value, product, quantity, selectedOptions);
      });
    });
  });
}

/* Update cart tab button
  ============================================================ */
function updateCartTabButton() {
  client.checkout
    .fetch(cart.id)
    .then((cart) => {
      let cartLinks = document.querySelectorAll('.js-nav-cart');
      if (cart.lineItems.length > 0) {
        cartLinks.forEach((link) => {
          link.classList.add('has-items');
        });
      } else {
        cartLinks.forEach((link) => {
          link.classList.remove('has-items');
        });
      }
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
}

/* Attach and control listeners onto buy button
 ============================================================ */
function buyButtonClickHandler(
  variantID,
  evt,
  customAttributes,
  quickBuy = false
) {
  const quantity = 1;

  const lineItemsToAdd = [
    {
      variantId: variantID,
      quantity: parseInt(quantity, 10),
      customAttributes: customAttributes,
    },
  ];

  const btns = document.querySelectorAll(`.js-add-to-cart[data-id="${variantID}"]`);

  // Add an item to the checkout
  client.checkout
    .addLineItems(cart.id, lineItemsToAdd)
    .then((checkout) => {
      cart = checkout;

      if (!quickBuy) {
        return checkout;
      }

      window.open(checkout.webUrl, '_self');
    })
    .then(() => {
      updateCartTabButton();

      const SUCCESS_TIMEOUT_MS = 4000;

      btns.forEach((btn) => {
        btn.removeAttribute('disabled');
        btn.classList.remove('processing');
        btn.classList.add('success');

        // If it's the product page, show success state
        if (btn.classList.contains('single-product__bag-btn')) {
          btn.innerText = 'Added to bag';
        } else {
          // If not on product page, remove success class after timeout
          setTimeout(() => {
            btn.classList.remove('success');
          }, SUCCESS_TIMEOUT_MS);
        }
      });

      let variant;

      cart.lineItems.forEach((product) => {
        // check that the product still exists in Shopify
        if (product.variant.id === variantID) {
          variant = product;
        }
      });

      if (window.gtag) {
        buyButtonAnalytics(variant, cart);
      }

      return true;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      btns.forEach((btn) => {
        btn.removeAttribute('disabled');
        btn.classList.remove('processing');
      });
      // $(evt.target).siblings('.js-add-to-quote-err').fadeIn(300);
      // $(evt.target).siblings('.js-add-to-quote-err').attr('aria-hidden', 'false');
    });
}

/* Variant option change handler
  ============================================================ */
function setSelectedVariant(name, value, product, quantity, selectedOptions) {
  let variants = product.variants;

  let selectedVariant;
  let variantInvalid = false;

  // check if valid options
  // product.variants => variant.selectedOptions

  variants.forEach((variant) => {
    let valid = true;
    for (const option of variant.selectedOptions) {
      const selectedOption = selectedOptions.find(
        (selected) => selected.name === option.name
      );
      if (!selectedOption || selectedOption.value !== option.value) {
        valid = false;
        break;
      }
    }
    if (valid) {
      // Selected options are valid for this variant
      selectedVariant = variant;
    }
  });

  // if not valid, exclude the one we just clicked on (name) and find next valid option
  if (!selectedVariant) {
    variantInvalid = true;
    selectedVariant = variants.find((variant) => {
      return variant.selectedOptions.find(
        (option) => option.name == name && option.value === value
      );
    });
  }

  product.selectedVariant = selectedVariant;

  // since selected variant didn't match we need to update checked selectors to match new variant
  if (variantInvalid) {
    updateCheckedSelectors(name, value, product);
  }

  updateAddToCartBtn(product);

  let $selectedVariants = document.querySelectorAll(
    '.js-variant-selector input:checked'
  );

  $selectedVariants.forEach(($selectedVariant) => {
    const name = $selectedVariant.dataset.option;
    const value = $selectedVariant.value;
    const handle = $selectedVariant.dataset.product;

    getProductWithPublicSku({ client, handle }).then((product) => {
      showAvailableVariants(name, value, product);
    });
  });

  // TODO: we need to update the stock level notices, the existing code from reids won't work here and we need to grab locations somehow.

  updateProductPageStockStatus(product);

  updateVariantPrice(product, product.selectedVariant, quantity);

  // TODO: fetch the public SKU from the metafields and anywhere else it might be use in this file...
  updateVariantSku(product, product.selectedVariant);

  updateURLParams({ v: extractID(product.selectedVariant.id) });

  updateVariantImage(product.selectedVariant.id);
}

/* Updates selectors so they match currently selected variant
  ============================================================ */
function updateCheckedSelectors(name, value, product) {
  product.selectedVariant.selectedOptions.forEach((option) => {
    let input = document.querySelector(
      `.js-variant-selector input[data-product="${product.handle}"][data-option="${option.name}"][value="${option.value}"]`
    );
    input.classList.remove('disabled');
    input.checked = true;
  });
}

/* Updates add to cart button with selected variant ID
  ============================================================ */
function updateAddToCartBtn(product) {
  let addToCartBtn = document.querySelector(
    `.js-product[data-product="${product.handle}"] .js-add-to-cart`
  );

  let quickBuyBtn = document.querySelector(
    `.js-product[data-product="${product.handle}"] .js-quick-buy`
  );

  addToCartBtn?.setAttribute('data-id', product.selectedVariant.id);
  quickBuyBtn?.setAttribute('data-id', product.selectedVariant.id);
}

/* Disable any variant options that aren't available for a product
  ============================================================ */
function showAvailableVariants(name, value, product) {
  if (product.options && product.options.length > 1) {
    // grab all variant selector inputs for current product that aren't the ones we just changed

    let allOtherInputs = document.querySelectorAll(
      `.js-variant-selector input[data-product="${product.handle}"]:not([data-option="${name}"])`
    );

    let validOptions = [];

    product.variants.forEach((variant) => {
      if (
        variant.selectedOptions.some(
          (option) => option.name == name && option.value == value
        )
      ) {
        variant.selectedOptions.forEach((option) => {
          // make sure its not the option we just clicked and that its in stock
          if (option.name != name) {
            validOptions.push({
              name: option.name,
              value: option.value,
            });
          }
        });
      }
    });

    // visually disable any inputs that aren't valid
    allOtherInputs.forEach((input) => {
      if (
        !validOptions.some(
          (option) =>
            option.name == input.dataset.option && option.value == input.value
        )
      ) {
        input.classList.add('disabled');
      } else {
        input.classList.remove('disabled');
      }
    });
  }
}

/* Update product price based on variant
  ============================================================ */
function updateVariantPrice(product, variant, quantity) {
  // console.debug('updateVariantPrice', product, variant, quantity);
  if (!variant) {
    return;
  }

  if (!quantity) quantity = 1;

  const priceEls = document.querySelectorAll(
    `.js-product[data-product="${product.handle}"] .js-product-price`
  );

  let priceMarkup = '';
  // If has a different "compare-at" price, show that
  if (
    variant.compareAtPrice?.amount &&
    variant.price.amount !== variant.compareAtPrice.amount
  ) {
    priceMarkup += `<strike>${formatAsMoney(
      variant.compareAtPrice.amount * quantity
    )}</strike>`;
  }
  priceMarkup += `<span>${formatAsMoney(
    variant.price.amount * quantity
  )}</span>`;

  // Update prices on page
  priceEls.forEach((price) => {
    price.dataset.price = variant.price.amount * quantity;
    price.innerHTML = priceMarkup;
  });
}

/* Update product sku based on variant
============================================================ */
function updateVariantSku(product, variant) {
  if (variant) {
    // console.log(variant);
    let sku = document.querySelector(`.js-product[data-product="${product.handle}"] .js-sp-sku`);
    if (sku) {
      sku.innerHTML = variant.publicSku || variant.sku;
    }

    // Update any sku fields on the page
    const skuFields = document.querySelectorAll('input.js-update-with-variant[name="sku"]');
    skuFields.forEach((field) => {
      field.value = variant.publicSku || variant.sku;
    });
  }
}

/**
 * Update store availability banner
 * @param { any[] } locations
 * @param { Boolean } available If the item is available in store
 */
function setStoreAvailability(locations, available) {
  const storeAvailability = document.querySelector('.js-store-availability');
  storeAvailability.classList.toggle(
    'single-product__notice--availability',
    available
  );

  if (available) {
    const locationString = locations
      .map((location) => {
        return `<a href="/contact-us">${location.location}</a>`;
      })
      .join(' and ');

    storeAvailability.querySelector(
      'p'
    ).innerHTML = `Available in-store at ${locationString}`;
  } else {
    storeAvailability.querySelector('p').innerHTML = 'Not available in-store';
  }
}

/**
 * Update online availability banner
 * @param { Boolean } available If the item is available online
 */
function setOnlineAvailability(available) {
  const onlineAvailability = document.querySelector('.js-online-availability');
  onlineAvailability.classList.toggle(
    'single-product__notice--availability',
    available
  );

  if (available) {
    onlineAvailability.querySelector('p').innerHTML =
      'Available to ship immediately';
  } else {
    onlineAvailability.classList.add('single-product__notice--info');
    onlineAvailability.querySelector('p').innerHTML = 'Not available online';
  }
}

/* Check stock levels and update page elements
  ============================================================ */
function updateProductPageStockStatus(product) {
  if (!document.querySelector('main.product')) {
    return;
  }

  // `hidePrice` is from our Shopify model.
  // It's added as a data attribute on the product page
  // because we don't have tags in the JS model from Shopify.
  const hidePrice = document.querySelector(
    '.js-product[data-price-hidden="true"]'
  )
    ? true
    : false;

  fetch(`/api/shop/variant-stock?id=${product.selectedVariant.id}`, {
    method: 'get',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      const inventory = response.result;

      let locations = inventory.filter((location) => location.available);

      if (locations.length && !hidePrice) {
        enableBuyButtons();
        setStoreAvailability(locations, true);
        setOnlineAvailability(true);
      } else if (locations.length && hidePrice) {
        disableBuyButtons();
        setStoreAvailability(locations, true);
        setOnlineAvailability(false);
      } else {
        disableBuyButtons();
        // show out of stock messaging
        setStoreAvailability(locations, false);
        setOnlineAvailability(false);
      }
    });
}

function disableBuyButtons() {
  document
    .querySelector('.js-add-to-cart')
    ?.setAttribute('disabled', 'disabled');
  document.querySelector('.js-buy-now')?.setAttribute('disabled', 'disabled');
}

function enableBuyButtons() {
  document.querySelector('.js-add-to-cart')?.removeAttribute('disabled');
  document.querySelector('.js-buy-now')?.removeAttribute('disabled');
}

function buyButtonAnalytics(product, cart) {
  //TODO: convert to GA4

  window.gtag('event', 'add_to_cart', {
    currency: cart.currencyCode,
    items: [
      {
        item_id: product.variant.sku,
        item_name: product.title,
        item_variant: product.variant.title,
        price: product.variant.price.amount,
        currency: cart.currencyCode,
        quantity: 1,
      },
    ],
    value: product.variant.price.amount,
  });

  window.fbq('track', 'AddToCart', {
    currency: cart.currencyCode,
    items: [
      {
        item_id: product.variant.sku,
        item_name: product.title,
        item_variant: product.variant.title,
        price: product.variant.price.amount,
        currency: cart.currencyCode,
        quantity: 1,
      },
    ],
    value: product.variant.price.amount,
  });
}

function updateURLParams(params) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  for (const key in params) {
    urlParams.set(key, params[key]);
  }
  window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);

  // Update any URL fields on the page
  const urlFields = document.querySelectorAll('input.js-update-with-variant[name="url"]');
  urlFields.forEach((field) => {
    field.value = window.location.href;
  });
}

export default {
  buyButtonClickHandler,
};

export { buyButtonClickHandler };
